/* eslint-env node */
/*
 * This file is automatically compiled by Webpack, along with any other files
 * present in this directory. You're encouraged to place your actual application logic in
 * a relevant structure within app/javascript and only use these pack files to reference
 * that code so it'll be compiled.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Router, Redirect, Route, Switch} from 'react-router-dom';
import {createBrowserHistory, createMemoryHistory} from 'history';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import configureStore from './store';
import Routes from '../components/Routes';
import rootReducer from '../reducers/rootReducer';
import {PersistGate} from 'redux-persist/integration/react';
import ScrollToTop from '../components/ScrollToTop';
import ReactGA from 'react-ga4';
import ModalManager from '../components/ModalManager';
import MainContentModal from '../components/MainContentModal';

require('@rails/ujs').start();
require('turbolinks').start();
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

const persistConfig = {
    key: 'root-pathways',
    storage
};

ReactGA.initialize(window.GOOGLE_GTAG, {
    anonymizeIp: true
});

const responseId = document.cookie.replace(/(?:(?:^|.*;\s*)response_id\s*=\s*([^;]*).*$)|^.*$/, '$1');

if (responseId.length > 0) {
    ReactGA.set({userId: responseId});
}

/*
 * In production, do not generate browser history. Only keep the memory history required by React router,
 * but, within the browser, this website will look like a single page
 */
const history = process.env.RAILS_ENV === 'development' ? createMemoryHistory() : createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore(persistedReducer, history);
const persistor = persistStore(store);

const loadHGTVContent = () => {
    const MAX_SECONDS_TO_LOAD_CONTENT = 5;
    window.timeStamp ||= new Date();
    const now = new Date();
    const secondsElapsed = (now.getTime() - window.timeStamp.getTime()) / 1000;

    const object = document.getElementsByTagName('object')[0];

    try {
        /*
         * it may take a bit of time for the iFrame to load, and we don't want to make
         * any content decisions until we have waited a certain period of time
         */
        if (secondsElapsed < MAX_SECONDS_TO_LOAD_CONTENT) {
            setTimeout(loadHGTVContent, 100);
        }
        else if (object.contentDocument.body.children.length === 0) {
            /*
             * We can only end up here if the domain does not have a website
             * in which case we remove the object from the webpage in order
             * to allow users to click underneath the object, which would have
             * a fallback Pinterest page. Without removing this, the Pinterest
             * page cannot be clicked on or scrolled through
             */
            object.parentNode.removeChild(object);
        }
    }
    catch (ex) {
        /*
         * if we end up here, that means object.contentDocument resulted in an exception
         * which can only happen for one reason: the domain hosts a valid website
         * and the browser is preventing access to that website's code for security reasons
         */
    }
};

document.addEventListener('DOMContentLoaded', loadHGTVContent);

const ReactApp = () =>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <ModalManager>
                    {modalProps =>
                        <React.Fragment>
                            <MainContentModal hideModals={modalProps.hideModals} {...modalProps.state}>
                                <ScrollToTop>
                                    <Switch>
                                        <Route component={Routes} path='/:locale'/>
                                        <Redirect to='/en'/>
                                    </Switch>
                                </ScrollToTop>
                            </MainContentModal>
                        </React.Fragment>
                    }
                </ModalManager>
            </Router>
        </PersistGate>
    </Provider>;

document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(<ReactApp name='React'/>,
        document.getElementById('main').appendChild(document.createElement('div')));
});